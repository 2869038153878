<template>
  <v-form>
    <v-row class="my-0">
      <template v-for="item in formFields">
        <v-col v-bind="item.colProps" :key="`col-${item.name}`" class="py-0">
          <component
            :is="item.comp"
            :loading="loading"
            background-color="white"
            outlined
            v-bind="item.props"
            :label="item.label"
            :data-vv-as="item.label"
            :data-vv-name="item.name"
            v-validate="item.validation"
            v-on="item.on"
            v-mask="item.mask"
            :placeholder="item.placeholder"
            :error-messages="errors.collect(`${item.name}`)"
            v-model="formData[item.name]"
            :key="item.name"
            :ref="item.name"
          >
            <template v-slot:default v-if="item.content">
              <div v-html="item.content"></div>
            </template>
          </component>

        </v-col>
      </template>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'AppForm',
  props: {
    formFields: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    containerClass: {
      type: String,
      default: () => null
    }
  }
}
</script>

<style scoped lang="scss">

</style>
